import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import { InPageNavigationRoundtables } from "../components/inpage-navigation"
import { InPageNavigationRoundtables } from "../components/inpage-navigation"
import sundo from "../images/logo/sundo.svg"
import qustomdot from "../images/logo/qustomdot.png"
import headlight from "../images/logo/headlight.svg"
import axithra from "../images/logo/axithra.png"
import alfasent from "../images/logo/alfasent.png"
import azaleavision from "../images/logo/azaleavision.jpg"
import eniris from "../images/logo/eniris.png"

const Roundtables = () => (
    <Layout>
        <Seo title="FEARS Roundtables" />
        <InPageNavigationRoundtables />
        <div className="fluid-container">
            <div className="row">
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Roundtables</h1>

                    <h2 id="roundtable">Industry Roundtables</h2>
                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select the roundtables)</a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <h3>Target group</h3>
                            <p>All PhD students or other researchers.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Participant limit</h3>
                            <p>5 x 8 places.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Date</h3>
                            <p>Wednesday November 27th (FEARS), 15:45-16:30</p>
                        </div>
                    </div>
                    {/*}
                    <div className="row pbottom-default">
                        <div className="col-sm-4">
                            <p>All PhD students or other researchers.</p>
                        </div>
                        <div className="col-sm-4">
                            <p>5 x 8 places.</p>
                        </div>
                        <div className="col-sm-4">
                            <p>Thursday October 26th (FEARS), 15:45-16:30</p>
                        </div>
                    </div>
                    */}

                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h3>Session content</h3>
                            <p>
                                The round table sessions provide the opportunity for startups and PhD students to
                                connect and discuss the startups business idea, R&D etc. Each round table will consist
                                of the representative(s) of a startup, a moderator, and max. 8 PhD students. The
                                subscriptions for this session will open September 16th.
                            </p>
                            {/*
                            <p>
                                The Industry Roundtables, introduced for the first time at FEARS, promise an enriching
                                experience tailored to PhD researchers eager to connect with industry. A total of 10
                                Roundtables will be organised covering the spectrum of engineering and architecture
                                disciplines present at our faculty. Each table will evolve around a hot topic in
                                engineering or architecture, represented by at least one leading company in this field.
                                The participation limit is set to 8 researchers per table. This to guarantee an
                                interactive and focused setting for your discussion.
                            </p>
                            <p>
                                This format allows you to closely engage with industry specialists, dive deeper into
                                topics mirroring your academic pursuits and share first hand insights on the state of
                                the art in your field! This hands-on workshop not only empowers researchers with a
                                clearer understanding of trends in industry but also provides participating companies
                                with a platform to showcase their commitment to innovation and benchmark their
                                advancements against academia. Attendees are encouraged to send in questions during
                                registration, ensuring an interactive and well-rounded dialogue.
                            </p>
                            */}
                        </div>
                        {/* <div className="col-sm-8">
                            <img width={840} src={roundtablecompanies} alt="Roundtable companies at FEARS2023"></img>
                        </div> */}
                    </div>

                    <div className="col-sm-12">
                        <div className="row pbottom-default">
                            <h2>Roundtable topics</h2>
                            <p>Participants of the <Link to="/workshops#questions">workshop on Q&amp;A</Link> will get
                                priority selection of their preferred roundtable topic.</p>

                            {/*<p><i>As we get closer to the event, you will find the participating companies below.</i>
                            </p>*/}

                            <div className="row pbottom-default" id="Sundo">
                                <div className="col-sm-2">
                                    <h3>TOPIC 1</h3>
                                    <p>Sundo</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>From ideation to startup and beyond (business > technical)</h3>
                                    <p>Sundo aims to change the global view of UV prevention through smart and
                                        innovative technology. Together with our customers and partners, our
                                        personalized sunscreen dispensers provide free sunscreen to people worldwide, at
                                        locations and times when it is needed most.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={sundo}
                                         alt="Sundo"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="QustomDot">
                                <div className="col-sm-2">
                                    <h3>TOPIC 2</h3>
                                    <p>QustomDot</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>How does the R&D process in industry differ from the research process at a
                                        university?</h3>
                                    <p>QustomDot enables displays of the future with its quantum dot (QD) color
                                        conversion technology. QDs, a Nobel Prize winning technology, are tiny inorganic
                                        semiconductor particles that are very efficient light emitters. QustomDot
                                        combines its expertise in QD synthesis, surface modification and ink formulation
                                        to develop best QD color conversion solutions to its customers in the display
                                        industry. Its product is a user-friendly QD ink developed to simplify microLED
                                        display manufacturing.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={qustomdot}
                                         alt="QustomDot"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="Headlight">
                                <div className="col-sm-2">
                                    <h3>TOPIC 3</h3>
                                    <p>Headlight</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>From PhD to startup</h3>
                                    <p>The Headlight platform provides organisations with the tools to organise their
                                        company car fleet and corporate mobility processes seamlessly, via deeply
                                        integrated and highly automated processes for car policy configuration,
                                        ordering, after-order services, invoicing, reporting and more. Our no-code
                                        platform is highly customisable, to accommodate the needs of every company that
                                        manages a large fleet of company cars.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={headlight}
                                         alt="Headlight"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="Axithra">
                                <div className="col-sm-2">
                                    <h3>TOPIC 4</h3>
                                    <p>Axithra</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3> Axithra technology explained in practice
                                    </h3>
                                    <p>Axithra is developing a platform to quickly and accurately measure and monitor
                                        drug concentrations in blood, paving the way for treatments tailored to the
                                        specific needs of each individual, at any given time. The first application is
                                        focusing on personalizing beta-lactam antibiotics treatments in the intensive
                                        care unit.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={axithra}
                                         alt="Axithra"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="Alfasent">

                                <div className="col-sm-2">
                                    <h3>TOPIC 5</h3>
                                    <p>Alfasent</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Using AI for communication/marketing at a startup</h3>
                                    <p>AlfaSent offers a web application where companies can analyze the sentiment of
                                        all their customer feedback thanks to AI. This includes reviews, social media
                                        posts, chats with clients and survey answers. </p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={alfasent}
                                         alt="Alfasent"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="AzaleaVision">
                                <div className="col-sm-2">
                                    <h3>TOPIC 6</h3>
                                    <p>Azalea Vision</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Azalea Vision technology explained and put in practice
                                    </h3>
                                    <p>Azalea Vision is a pioneering company at the forefront of smart contact lens
                                        technology. Their mission is to seamlessly integrate cutting-edge technology
                                        into everyday life, revolutionizing how we interact with the world around us. By
                                        combining advanced optics and microelectronics, Azalea Vision is developing
                                        smart contact lenses that enhance vision, provide real-time information, and
                                        enable novel forms of communication. With a focus on user experience and
                                        innovation, Azalea Vision is committed to shaping the future of vision
                                        enhancement. </p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={azaleavision} alt="Azalea Vision"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="AzaleaVision">
                                <div className="col-sm-2">
                                    <h3>TOPIC 7</h3>
                                    <p>Eniris</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Eniris technology explained in practice
                                    </h3>
                                    <p>Eniris is an international company headquartered in Belgium, 
                                        with a branch office in the Netherlands. 
                                        Founded in 2018, Eniris developed the SmartgridX platform 
                                        for managing various energy assets. Building on this, 
                                        Eniris BMS was developed to monitor buildings and their energy flows. 
                                        In 2020, Eniris launched SmartgridOne, an advanced energy management system. 
                                        Today, Eniris connects over 135,000 residential and 7,800 commercial installations worldwide, 
                                        covering more than 185 brands, establishing itself as a leader in energy monitoring and management. 
                                        </p>
                                </div>
                                <div className="col-sm-3">
                                    <img style={{ maxWidth: "300px", maxHeight: "100px" }} src={eniris} alt="Eniris"></img>
                                </div>
                            </div>
                            {/*
                            <div className="row pbottom-default" id="daikin">
                                <div className="col-sm-2">
                                    <h3>TOPIC 7</h3>
                                    <p>Daikin</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>What are possible renewable energy solutions in densely populated areas?
                                    </h3>
                                    <p>Daikin Europe is a leading provider of heating, cooling, ventilation, air purification and refrigeration technology for residential, commercial and industrial purposes. We offer a wide range of products, solutions and services to create indoor environments that are beneficial to people's health and wellbeing. Our advanced technology enables our customers to reduce their environmental impact and contribute to a more sustainable society. The EMEA Development Centre is where Daikin turns tangible research into the climate changing technologies of tomorrow.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={daikin} alt="Daikin"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="arcelor">
                                <div className="col-sm-2">
                                    <h3>TOPIC 8</h3>
                                    <p>ArcelorMittal</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>How a modelling department can make the difference in a steel company.
                                    </h3>
                                    <p>ArcelorMittal Belgium is part of the ArcelorMittal group, one of the world's leading steel and mining companies. Our Belgian cluster with sites in Ghent, Liège, Geel and Genk has all necessary facilities on site to convert the raw materials supplied in a sustainable and innovative way into steel products with high added value. Cars, wind turbines, design houses... everywhere you look, you come across steel from ArcelorMittal Belgium. ArcelorMittal Belgium is among the world's leaders in energy and CO2 efficiency and, as a high-tech company, is a pioneer in CO2 reduction. We have committed to reduce our CO2 emissions by 35% by 2030 compared to 2018, and to be carbon neutral by 2050. The concrete implementation of our CO2 roadmap towards carbon neutrality by 2050 is currently being deployed.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={arcelor} alt="ArcelorMittal"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="melexis">
                                <div className="col-sm-2">
                                    <h3>TOPIC 9</h3>
                                    <p>Melexis</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Sensors and Systems for digital health</h3>
                                    <p>Melexis is a global supplier of micro-electronic semiconductor solutions and stands for engineering that enables the best imaginable future - a future which is safe, clean, comfortable and healthy. That's what we call inspired engineering. Our technology makes cars and other products smarter, safer and greener. Our sensors capture data from the analog world and comprehend these data digitally. Our drivers make sure customers can bring their products to life.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={melexis} alt="Melexis"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="sirus">
                                <div className="col-sm-2">
                                    <h3>TOPIC 10</h3>
                                    <p>Sirus</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Data spaces, sharing of data between different organisations in order to solve pressing challenges our society is currently facing.</h3>
                                    <p>Sirus, founded in 2011, is a Belgian software company with a dynamic team of about 50 employees and focuses on development, architecture and consultancy. Sirus' mission is to guide customers through the rapidly changing IT landscape. Our expertise, extensive network, and customer-oriented approach are central to all our projects. Sirus delivers quality solutions specialized in .NET, M365, power platform, Azure, IoT and data solutions for smart cities and smart businesses. This expertise proves itself in our collaboration with Microsoft, as Microsoft Solutions Partner for Data & AI and for Digital and App innovation</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={200} src={sirus} alt="Sirus"></img>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </Layout>
)

export default Roundtables
